import request from "@/utils/request";

// 新增
export function saveManual(data) {
  return request({
    url: "api/v2/m_czsm/saveManual",
    method: "post",
    data,
  });
}

//查询
// export function getManual(data) {
//   return request({
//     url: "api/v2/m_czsm/getManual",
//     method: "post",
//     data,
//   });
// }
export function getManual(data) {
  return request({
    url: "api/public/getManual",
    method: "post",
    data,
  });
}
//删除
export function deleteManual(data) {
  return request({
    url: "api/v2/m_czsm/deleteManual",
    method: "post",
    data,
  });
}

export function getManualInfo(data) {
  return request({
    url: "api/v2/m_czsm/getManualInfo",
    method: "post",
    data,
  });
}
