<template>
  <div class="main">
    <div class="top">
      <div>
        <el-button size="small" type="primary" @click="addCourse"
          >新增一级标题</el-button
        >
      </div>
    </div>
    <div>
      <el-table
        :data="list"
        style="width: 100%"
        row-key="id"
        border
        default-expand-all
        :tree-props="{ children: 'children', hasChildren: 'hasChildren' }"
        :height="contentStyleObj"
        v-loading="loading"
      >
        <template #empty>
          <el-empty :image-size="150" description="没有数据"></el-empty>
        </template>
        <el-table-column prop="title" label="标题名称" />
        <el-table-column
          prop="content"
          label="标题内容"
          align="center"
          min-width="200px"
        >
          <template #default="scope">
            <span class="overflow" v-html="scope.row.content"></span>
          </template>
        </el-table-column>
        <el-table-column align="center" label="操作" width="300">
          <template #default="scope">
            <el-button
              type="success"
              @click="addCourse2(scope.row)"
              plain
              size="small"
              >新增标题</el-button
            >
            <el-button
              type="primary"
              @click="addCourse2(scope.row, 'edit')"
              plain
              size="small"
              >编辑</el-button
            >
            <el-button
              type="danger"
              @click="delCourse(scope.row)"
              plain
              size="small"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </div>
    <el-dialog
      :close-on-click-modal="false"
      destroy-on-close
      title="编辑"
      v-model="dialogFormVisible"
      width="80%"
    >
      <el-form>
        <el-form-item label="标题" :label-width="formLabelWidth">
          <el-input
            v-model="temp.title"
            size="small"
            style="width:96%"
          ></el-input>
        </el-form-item>
        <el-form-item label="活动区域" :label-width="formLabelWidth">
          <wangEditor v-model:html="temp.content"></wangEditor>
        </el-form-item>
      </el-form>
      <template #footer>
        <div class="dialog-footer">
          <el-button @click="dialogFormVisible = false" size="small"
            >取 消</el-button
          >
          <el-button type="primary" @click="submit" size="small"
            >确 定</el-button
          >
        </div>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import wangEditor from "../platform/components/wangEditor.vue";
import { saveManual, getManual, deleteManual } from "@/api/czsm";
export default {
  name: "",
  components: { wangEditor },
  props: {},
  data() {
    return {
      loading: false,
      formLabelWidth: "100px",
      list: [],
      defaultProps: {
        children: "children",
        label: "name",
      },
      temp: {
        title: "",
        content: "",
      },
      dialogFormVisible: false,
      dialogStatus: "",
    };
  },
  created() {
    this.getList();
    this.contentStyleObj = this.$getHeight(300);
  },
  methods: {
    getList() {
      this.loading = true;
      getManual({}).then((res) => {
        this.loading = false;
        if (res.data.msg == "success") {
          this.list = res.data.data.list;
        }
      });
    },

    //新增一级
    addCourse() {
      this.dialogFormVisible = true;
      this.dialogStatus = "add";
      this.temp = {
        title: "",
        content: "",
        parentId: 0,
      };
    },
    addCourse2(row, type) {
      this.dialogStatus == "";
      if (type == "edit") {
        this.temp = Object.assign({}, row);
      } else {
        this.temp = {
          title: "",
          content: "",
          parentId: row.id,
        };
      }
      this.dialogFormVisible = true;
    },
    submit() {
      if (!this.temp.title) {
        this.$message.error("请填写标题");
        return;
      }
      saveManual(this.temp).then((res) => {
        if (res.data.msg == "success") {
          this.dialogFormVisible = false;
          this.$qzfMessage("操作成功");
          this.getList();
          this.temp = {}
        }
      });
    },
    delCourse(row) {
      deleteManual({ id: row.id }).then((res) => {
        if (res.data.msg == "success") {
          this.$qzfMessage("删除成功");
          this.getList();
        }
      });
    },
  },
};
</script>

<style scoped lang="scss">
.main {
  width: 98%;
  margin: 0 auto;
}
.top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 15px 0;
}
.overflow {
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}
</style>
